import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Contexts/auth-context";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { getAllStandard } from "../../../services/standard/standard-service";
import { getCompanyByAlias } from "../../../services/util/util-service";

const Register = ({ setActiveTab }) => {
  const { register } = useAuth();
  const [statuses, setStatuses] = useState([]);
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [registerData, setRagisterData] = useState({
    fname: "",
    mname: "",
    lname: "",
    email: "",
    phone: "",
    password: "",
    dob: "",
    status: 0,
    company_id: "",
    gender: "",
  });
  const navigate = useNavigate();

  const getStandards = async () => {
    const res = await getAllStandard();
    if (res && res.data?.data?.records) {
      setStatuses(res.data.data?.records);
    }
  };

  const fetchCompanyByAlias = async (alias) => {
    await getCompanyByAlias(alias).then((res) => {
      if (res && res.data.data) {
        setRagisterData({
          ...registerData,
          company_id: res.data.data.company_id,
        });
      }
    });
  };

  useEffect(() => {
    getStandards();
    if (window && window.location?.hostname === "localhost") {
      fetchCompanyByAlias(window.location?.hostname);
    } else {
      fetchCompanyByAlias(window.location?.hostname);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    // const form = event.currentTarget;
    setLoading(true);

    // // if (form.checkValidity() === false) {
    //   event.stopPropagation();
    // // }
    // event.preventDefault();

    let res = await register(registerData);
    setLoading(false);
    if (res && res.data?.meta?.status) {
      toast.success("Registered Successfully");
      navigate("/student/about-career");
    }
  };
  return (
    <React.Fragment>
      <div>
        <form
          // noValidate
          // className="needs-validation"
          // validated={validated}
          onSubmit={handleSubmit}
        >
          <div className="form-group mb-3">
            <div className="row g-2">
              <div className="col">
                <input
                  type="text"
                  name="fname"
                  className="form-control"
                  placeholder="First Name"
                  required
                  value={registerData.fname}
                  onChange={(e) =>
                    setRagisterData({ ...registerData, fname: e.target.value })
                  }
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  name="lname"
                  className="form-control"
                  placeholder="Last Name"
                  required
                  value={registerData.lname}
                  onChange={(e) =>
                    setRagisterData({ ...registerData, lname: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row g-2">
              <div className="col mt-3">
                <input
                  type={registerData.dob ? "date" : "text"}
                  name="dob"
                  className="form-control"
                  placeholder="DOB"
                  max={new Date().toISOString().split("T")[0]} // Sets max date to today
                  value={registerData.dob}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => {
                    if (!registerData.dob) e.target.type = "text";
                  }}
                  onChange={(e) =>
                    setRagisterData({ ...registerData, dob: e.target.value })
                  }
                />
              </div>

              <div className="col mt-3">
                <select
                  className="form-select font-14 font-500 height-40"
                  style={{ position: "relative", zIndex: "2" }}
                  aria-label="Gender"
                  name="gender"
                  required
                  value={registerData.gender}
                  onChange={(e) =>
                    setRagisterData({ ...registerData, gender: e.target.value })
                  }
                >
                  <option value="">Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>

            </div>
          </div>

          <div className="form-group mb-3">
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Email"
              required=""
              value={registerData.email}
              onChange={(e) =>
                setRagisterData({ ...registerData, email: e.target.value })
              }
            />
            <div
              className="invalid-feedback"
              style={{ position: "relative", zIndex: "1" }}
            >
              Please Enter vaild Email Address
            </div>
          </div>

          <div className="form-group mb-3">
            <input
              type={passwordType}
              name="password"
              className="form-control"
              placeholder="Create Password*"
              value={registerData.password}
              onChange={(e) =>
                setRagisterData({ ...registerData, password: e.target.value })
              }
            />
            <span
              className="viewpassword"
              onClick={() =>
                setPasswordType(
                  passwordType === "password" ? "text" : "password"
                )
              }
            >
              <img src="/assets/images/view.svg" alt="view" />
            </span>
          </div>
          <div className="form-group mb-3">
            <div className="row g-2">
              {/* <div className="col-auto">
                <select
                  className="form-select font-14 font-500 height-40"
                  aria-label="broad"
                  required=""
                  defaultValue={""}
                >
                  <option value="">+91</option>
                  <option value="1">+90</option>
                  <option value="2">+91</option>
                  <option value="3">+92</option>
                </select>
              </div> */}
              <div className="col">
                <input
                  placeholder="Mobile"
                  className="form-control"
                  type="number"
                  name="phone"
                  value={registerData.phone}
                  onChange={(e) =>
                    setRagisterData({ ...registerData, phone: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group mb-3">
            <label
              className="text-white font-16 mt-3 mb-2 font-500"
              style={{ position: "relative", zIndex: "9" }}
            >
              Choose your status as a student
            </label>
            <div className="row g-2">
              {statuses &&
                statuses?.map((s, i) => {
                  return (
                    <div key={i} className="col-auto">
                      <input
                        className="fw-bold d-none correct-check-radio"
                        id={"status" + i}
                        name={"status" + i}
                        type="radio"
                        value={s.id}
                        checked={
                          parseInt(registerData.status) === parseInt(s.id)
                        }
                        onClick={(e) => {
                          if (registerData.status === e.target.value) {
                            setRagisterData({
                              ...registerData,
                              status: 0,
                            });
                          } else {
                            setRagisterData({
                              ...registerData,
                              status: e.target.value,
                            });
                          }
                        }}
                        onChange={(e) => console.log(e)}
                      />
                      <label
                        htmlFor={"status" + i}
                        className="correct-check-label"
                      >
                        <span>{s.name}</span>
                        <i></i>
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-completed radius height-45 max-200 mx-auto d-block w-100 mt-4 font-14 font-500"
            style={{ background: "#5cae48" }}
            // onClick={handleSubmit}
          >
            {loading ? (
              <div className="loader-div">
                <p className="loader"></p>
              </div>
            ) : (
              <span>Sign Up</span>
            )}
          </button>
        </form>
      </div>
    </React.Fragment>
  );
};

export default Register;
