import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { getAllStandards } from "../../../services/student/student-profile";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Contexts/auth-context";

const AddStudent = ({ addStudent, setAddStudent }) => {
  const { registerCounsellor, user } = useAuth();
  const navigate = useNavigate();
  const [statuses, setStatuses] = useState([]);
  const [validated, setValidated] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const [studentData, setStudentData] = useState({
    fname: "",
    mname: "a",
    lname: "",
    email: "",
    phone: "",
    password: "",
    dob: "",
    status: 0,
    company_id: user?.company_id,
  });

  const getStandards = async () => {
    const res = await getAllStandards();
    if (res && res.data?.data?.records) {
      setStatuses(res.data?.data?.records);
    }
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();

    const res = await registerCounsellor(studentData);
    setValidated(true);
    if (res && res.data?.meta?.status) {
      toast.success("Added Successfully");
      setAddStudent(false);
    }
  };

  useEffect(() => {
    getStandards();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "fname" || name === "lname") {
      // Accept only alphabets and spaces for fname and lname
      if (/^[A-Za-z\s]*$/.test(value)) {
        setStudentData({ ...studentData, [name]: value });
      }
    } else if (name === "phone") {
      // Accept only 10 or 12 digits for phone number
      if (/^\d{0,12}$/.test(value)) {
        setStudentData({ ...studentData, [name]: value });
      }
    } else {
      setStudentData({ ...studentData, [name]: value });
    }
  };
  

  return (
    <Modal show={addStudent} centered onHide={() => setAddStudent(false)}>
      <div className="modal-header border-0 d-flex justify-content-between w-full">
        <h5 className="modal-title font-24 text-dark-blue">Add Student</h5>
        <button
          type="button"
          className="btn-close btn-close-small"
          onClick={() => setAddStudent(false)}
        ></button>
      </div>
      <div className="modal-body pt-0">
        <Form className="needs-validation" onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <div className="row g-2 mb-2">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  name="fname"
                  required
                  value={studentData.fname}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  required
                  name="lname"
                  value={studentData.lname}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row g-2">
              <div className="col mt-3">
                <input
                  type="date"
                  className="form-control"
                  placeholder="Date Of Birth"
                  name="dob"
                  value={studentData.dob}
                  onChange={(e) =>
                    setStudentData({ ...studentData, dob: e.target.value })
                  }
                />
              </div>
              <div className="col mt-3">
                <div className="form-group">
                  <div className="row g-2">
                    <div className="col">
                      <input
                        placeholder="Mobile"
                        className="form-control"
                        type="text"
                        name="phone"
                        value={studentData.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group mb-3">
            <input
              type="email"
              name="email"
              className="form-control"
              value={studentData.email}
              onChange={(e) =>
                setStudentData({
                  ...studentData,
                  email: e.target.value,
                })
              }
              required
              placeholder="Email"
            />
            <div className="invalid-feedback">
              Please Enter vaild Email Address
            </div>
          </div>
          <div className="form-group mb-2 position-relative">
            <input
              type={passwordType}
              className="form-control"
              id="password"
              onChange={(e) =>
                setStudentData({
                  ...studentData,
                  password: e.target.value,
                })
              }
              placeholder="Create Password*"
            />
            <span
              className="viewpassword cursor-pointer position-absolute"
              onClick={() =>
                setPasswordType(
                  passwordType === "password" ? "text" : "password"
                )
              }
              style={{ right: "10px", top: "50%", transform: "translateY(-50%)" }}
            >
              <img src="/assets/images/view.svg" alt="view" />
            </span>
          </div>
          <div className="form-group mb-3">
            <label
              className=" font-16 mt-3 mb-2 font-500"
              style={{ position: "relative", zIndex: "9" }}
            >
              Choose your status as a student
            </label>
            <div className="row g-2">
              {statuses &&
                statuses?.map((s, i) => {
                  return (
                    <div key={i} className="col-auto">
                      <input
                        className="d-none correct-check-radio"
                        id={"status" + i}
                        name={"status" + i}
                        type="radio"
                        value={s.id}
                        checked={studentData.status == s.id}
                        onClick={(e) => {
                          if (studentData.status === e.target.value) {
                            setStudentData({
                              ...studentData,
                              status: 0,
                            });
                          } else {
                            setStudentData({
                              ...studentData,
                              status: e.target.value,
                            });
                          }
                        }}
                      />
                      <label
                        htmlFor={"status" + i}
                        className="correct-check-label"
                        style={{ backgroundColor: "#e9e9e9" }}
                      >
                        <span>{s.name}</span>
                        <i></i>
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-completed radius height-45 max-200 mx-auto d-block w-100 mt-4 font-14 font-500"
            style={{ background: "#5cae48" }}
          >
            <span>Submit</span>
          </button>
        </Form>
      </div>
    </Modal>
  );
};

export default AddStudent;
